import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/_yolOZowjok">
    <SEO title="Earning and Exuding - Hebrews" />
  </Layout>
)

export default SermonPost
